import { useState, useEffect } from "react";
import HiringPerson from "../../../images/Home/hiring-person.png";
import PaulPic from "../../../images/Home/paul-home-pic.png";
import Map from "../../../images/Home/hiring-step-1.png";
import Search from "../../../images/Home/hiring-step-2.png";
import Candidate from "../../../images/Home/hiring-step-3.png";
import Step1 from "../../../images/Home/step-1.png";
import Step2 from "../../../images/Home/step-2.png";
import Step3 from "../../../images/Home/step-3.png";
import Step4 from "../../../images/Home/step-4.png";
import Step5 from "../../../images/Home/step-5.png";
import Nurse from "../../../images/Home/lady-nurse.png";
import NurseSingle from "../../../images/Home/lady-nurse-single.png";
import Ellipse from "../../../images/Home/Ellipse.png";
import HomePricing from "./HomePricing";
import { BsChevronRight } from "react-icons/bs";
import PrevButton from "../../../images/Home/Prev-butt.png";
import NextButton from "../../../images/Home/next-butt.png";
import QoutesIcon from "../../../images/Home/testimonial-icon.png";
import HomeMap from "./HomeMap";
import useGetData from "../../../hooks/useGetData";
import { UserContext } from "../../../App";
import { useContext } from "react";
import HelpForm from "./HelpForm";

function NewHome() {
  const url = "testimonials";
  const { data: testimonialData } = useGetData(url);
  const [desc, setDesc] = useState(null);
  const [testId, setTestId] = useState(null);
  console.log("testId", testId);
  const [activeLogoLabel, setActiveLogoLabel] = useState(null);
  const username = localStorage.getItem("username");
  const user_role = localStorage.getItem("user_role");
  // const { username } = useContext(UserContext);
  console.log("user_role..", user_role);

  useEffect(() => {
    setDesc(testimonialData?.[3]?.description);
    setTestId(testimonialData?.[3]?.id);
    setActiveLogoLabel(testimonialData?.[3]?.company_name);
  }, [testimonialData]);

  const nextClick = () => {
    if (testId == testimonialData[testimonialData.length - 1].id) {
      setTestId(testimonialData[0].id);
    } else {
      setTestId(testimonialData[0].id);
    }
  };
  const prevClick = () => {
    if (testId >= 2) {
      setTestId(testId - 1);
    } else {
      setTestId(7);
    }
  };

  useEffect(() => {
    var feedback = testimonialData?.find(
      (singletestimonial) => singletestimonial.id === testId
    );
    setDesc(feedback?.description);
    setActiveLogoLabel(feedback?.company_name);
  }, [testId]);

  const logoClick = (logoID) => {
    console.log("id..", logoID);
    var updateDesc = testimonialData?.find(
      (singletestimonial) => singletestimonial.id === logoID
    );
    setDesc(updateDesc.description);
    setActiveLogoLabel(updateDesc.company_name);
    setTestId(updateDesc.id);
  };

  return (
    <>
      {/* Stress-Free Hiring For Your Practice */}
      <div className="employer-banner relative py-10 lg:py-0">
        <div className="flex flex-col lg:flex-row text-white items-center justify-center xl:w-5/6 lg:mx-auto pt-12 pb-6 px-10 xl:px-4 relative gap-10 2xl:gap-96">
          <div>
            <h1 className="text-4xl md:text-4xl font-bold mb-2 md:mb-8 lg:mb-8 text-center lg:text-left">
              Increase Profits, Reduce Expenses
            </h1>
            <p className="mb-5 text-xl lg:text-2xl font-semibold text-center md:text-left lg:text-left">
              AI & Hiring Solutions Built for Medical Offices
            </p>
            <ul className="mb-5 list-disc ml-5 text-xl lg:text-2xl flex flex-col gap-4">
              <li>Never Be Short-Staffed Again with Katie AI, Your 24/7 Medical Receptionist</li>
              <li>Cut Labor Costs, Boost Efficiency</li>
              <li>Stress-Free Hiring</li>
              <li>Around-the-Clock Coverage</li>
              <li>Happier Employees, Less Turnover</li>
              <li>Website Optimization & Maintenance Included</li>
            </ul>
            <div className="flex flex-col md:flex-row lg:flex-row">
              {!username ? (
                <a
                  href="/signup"
                  className="bg-transparent text-white px-5 py-2 text-xl font-semibold rounded-lg hover:border-red-400 border-2 border-red-500 mt-7 flex flex-row items-center flex-none w-52 justify-center"
                >
                  Get Started{" "}
                  <BsChevronRight  className="ml-1 text-white" size="1em" />
                </a>
              ) : user_role === "employee" ? (
                <a
                  href="/profile/employee-dashboard"
                  className="bg-transparent text-white px-5 py-2 text-xl font-semibold rounded-lg hover:border-red-400 border-2 border-red-500 mt-7 flex flex-row items-center flex-none w-52 justify-center"
                >
                  Get Started{" "}
                  <BsChevronRight className="ml-1 text-white" size="1em" />
                </a>
              ) : (
                <a
                  href="/profile/employer-dashboard"
                  className="bg-transparent text-white px-5 py-2 text-xl font-semibold rounded-lg hover:border-red-400 border-2 border-red-500 mt-7 flex flex-row items-center flex-none w-52 justify-center mx-auto lg:mx-0"
                >
                  Get Started{" "}
                  <BsChevronRight  className="ml-1 text-white" size="1em" />
                </a>
              )}

              <a
                href="https://www.katie.ringofhires.com/"
                className="bg-red-500 text-white px-5 py-2 text-xl font-semibold rounded-lg hover:bg-red-600 border border-red-500 mt-7 flex flex-row items-center flex-none w-80 justify-center mx-auto  ml-0 md:ml-2 lg:ml-2"
              >
                Demo AI Receptionist
                <BsChevronRight color="ffffff" className="ml-1" size="1em" />
              </a>

            </div>
          </div>
          <img src={Nurse} className="z-10 lg:w-5/12 xl:w-auto mt-10 lg:mt-0 hidden lg:block" />
          <img src={NurseSingle} className="z-10 lg:w-5/12 xl:w-auto mt-10 lg:mt-0 block lg:hidden" />
        </div>
      </div>
      <div>
        <img src={Ellipse} className="absolute hidden lg:block lg:top-20 xl:top-60 right-0 lg:w-3/12 xl:w-auto" />
      </div>

      {/* Hiring headache */}
      <div className="flex flex-col lg:flex-row justify-center gap-5 xl:gap-10 items-center my-10 xl:w-5/6 lg:mx-auto p-6">
        <img src={HiringPerson} />
        <div>
          <h2 className="text-3xl md:text-4xl font-bold mb-5 text-center lg:text-left">
            Overwhelmed by Office Chaos and Hiring Nightmares? Here’s What’s Holding You Back:
          </h2>
          <ul className="mb-5 list-disc ml-5 text-xl text-gray-500 leading-8">
            <li>
              Can’t find qualified healthcare professionals fast enough, leaving your team stretched thin.
            </li>
            <li>Your staff is drowning in endless scheduling and admin tasks instead of focusing on patients.</li>
            <li>
              Wasting hours (and money) on bad candidates who don’t fit your needs.
            </li>
            <li>
              Every "solution" you’ve tried is either too complex or just doesn’t deliver.
            </li>
          </ul>
          {/* <button className="btn border border-main px-4 py-2 text-main rounded-lg text-lg">
            Try it Out
          </button> */}
          {!username ? (
            <a
              href="/signup"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          ) : user_role === "employee" ? (
            <a
              href="/profile/employee-dashboard"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          ) : (
            <a
              href="/profile/employer-dashboard"
              className="block w-36 border border-main px-4 py-2 text-main rounded-lg text-lg text-center"
            >
              Try it Out
            </a>
          )}
        </div>
      </div>

      {/* Map */}
      <div className="my-16 bg-main px-10 py-20">
        <h2 className="text-3xl md:text-4xl font-semibold mb-3 text-center text-white">
          Looking for a Job?
        </h2>
        <p className="text-center text-white mb-10">
          {" "}
          Find your ideal healthcare career with us.
        </p>

        <div className="mx-auto lg:w-5/6 bg-white pt-5 p-1 rounded-xl">
          <HomeMap />
        </div>
      </div>

      {/* The Solution to your Hiring Woes */}
      <div className="py-14 px-10  bg-blue-200 rounded-lg my-16 xl:w-5/6 lg:mx-auto p-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center">
          The Solution to Your Staffing and Efficiency Woes
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 gap-6">
          <div className="bg-white p-10 rounded-lg">
            <img src={Map} className="p-5 bg-blue-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">Never Be Short-Staffed Again</h4>
            <p className="text-gray-500 text-xl">
              With Katie AI, your 24/7 medical receptionist, and our recruitment services, you’ll never have to worry about being understaffed again.
            </p>
          </div>
          <div className="bg-white p-10 rounded-lg">
            <img src={Search} className="p-5 bg-red-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">Cut Labor Costs, Boost Efficiency</h4>
            <p className="text-gray-500 text-xl">
              Let AI handle repetitive tasks like scheduling and after-hours calls. With services priced at just $0.99 per minute, you’ll save on staffing costs while increasing productivity.
            </p>
          </div>
          <div className="bg-white p-10 rounded-lg">
            <img src={Candidate} className="p-5 bg-green-100 rounded-lg mb-7" />
            <h4 className="font-bold text-2xl mb-3">
              Stress-Free Hiring
            </h4>
            <p className="text-gray-500 text-xl">
              Streamline your hiring process with a dedicated Firefly Guide who does the heavy lifting for you—setting up interviews, screening candidates, and delivering top talent to your practice.
            </p>
          </div>
        </div>
      </div>

      {/* Hear From Practices Like Yours */}
      {/* <div className="my-16 xl:w-5/6 lg:mx-auto p-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center">
          Hear From Practices Like Yours
        </h2>
        <div className="flex items-center gap-7 mx-auto w-4/6">
          <div className="prev-butt">
            <img
              src={PrevButton}
              className="w-32 h-auto cursor-pointer"
              onClick={prevClick}
            />
          </div>
          <div className="border rounded-lg px-10 py-16 shadow-lg">
            <div className="flex gap-5">
              <img src={QoutesIcon} className="block w-auto h-5" />
              <p className="text-xl text-gray-500 leading-8 transition-transform duration-500 transform">
                {desc}
              </p>
            </div>
          </div>
          <div className="next-butt">
            <img
              src={NextButton}
              className="w-32 h-auto cursor-pointer"
              onClick={nextClick}
            />
          </div>
        </div>
        <div className="flex gap-9 mt-10 justify-center">
          {testimonialData?.map((singleCompIcon, index) => (
            <div className="flex flex-col justify-center">
              <img
                src={singleCompIcon?.company_logo}
                className="block w-14 h-auto cursor-pointer mx-auto"
                onClick={() => {
                  logoClick(singleCompIcon?.id);
                }}
              />
              <div className="text-lg font-bold mx-auto">
                {singleCompIcon.id === testId && activeLogoLabel}
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* Pricing & plan  */}
      <div className="my-16 bg-main px-10 pt-16 pb-10">
        <h2 className="text-3xl md:text-4xl font-semibold mb-5 text-center text-white">
          Pick Your Ideal Hiring Solution
        </h2>
        <HomePricing />
      </div>

      {/* How to Simplify Your Hiring Process */}
      <div className="my-16 bg-blue-100">
        <div className="py-20 mx-auto sm:w-4/6 px-7 sm:px-0">
          <h2 className="text-3xl md:text-4xl font-bold mb-16 text-center">
            How to Streamline Your Office and Hiring Process
          </h2>
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-10 mb-20">
            {/* Step 1 */}
            <div className="block space-y-3">
              <img src={Step1} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Setup your profile
              </h4>
              <p className="text-gray-500 text-lg">
                Create your account and easily set up your business profile, laying the foundation for optimized hiring and operational efficiency.
              </p>
            </div>
            {/* Step 2 */}
            <div className="block space-y-3">
              <img src={Step2} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Automate Repetitive Tasks
              </h4>
              <p className="text-gray-500 text-lg">
                Let Katie AI handle scheduling, after-hours calls, and routine tasks, freeing up your team to focus on patient care.
              </p>
            </div>
            {/* Step 3 */}
            <div className="block space-y-3">
              <img src={Step3} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Streamline Your Candidate Search
              </h4>
              <p className="text-gray-500 text-lg">
                Customize your search criteria to find qualified healthcare professionals, while our Firefly Guide takes care of interview setup and candidate screening.{" "}
              </p>
            </div>
            {/* Step 4 */}
            <div className="block space-y-3">
              <img src={Step4} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Manage Everything from One Dashboard
              </h4>
              <p className="text-gray-500 text-lg">
                View available candidates, track applications, and manage AI-powered workflows in a single, easy-to-use platform.
              </p>
            </div>
            {/* Step 5 */}
            <div className="block space-y-3">
              <img src={Step5} className="w-14 h-14" />
              <h4 className="text-xl sm:text-2xl font-semibold">
                Start Interviewing Top Talent
              </h4>
              <p className="text-gray-500 text-lg">
                Easily chat with candidates and schedule interviews, all while AI keeps your office running efficiently.
              </p>
            </div>
          </div>

          {/* <button className="bg-main text-white text-center px-16 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700">
            Get Started
          </button> */}
          {!username ? (
            <a
              href="/signup"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          ) : user_role === "employee" ? (
            <a
              href="/profile/employee-dashboard"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          ) : (
            <a
              href="/profile/employer-dashboard"
              className="bg-main text-white text-center px-4 py-4 text-xl font-semibold rounded-lg mx-auto block hover:bg-blue-700 w-48"
            >
              Get Started{" "}
            </a>
          )}
        </div>
      </div>

      {/* Paul's profile */}
      <div className="flex flex-col lg:flex-row justify-center gap-5 xl:gap-10 items-center my-10 xl:w-5/6 lg:mx-auto p-6">
        <div>
          <h1 className="text-2xl md:text-3xl font-bold mb-5 text-center lg:text-left">
            A Doctor's Solution to the Challenges of Healthcare Staffing
          </h1>
          <p className="mb-2 text-xl text-gray-500 leading-8">
            As a doctor, I know firsthand the frustration of trying to maintain high-quality care while juggling staffing issues. Searching for the right candidates can be overwhelming, especially when you’re trying to support both your patients and your hardworking team. It’s disheartening when the hiring process slows you down, leaving critical positions unfilled and increasing the strain on everyone.</p>
          <p  className="mb-5 text-xl text-gray-500 leading-8">
            That’s why I created Ring of Hires—not only to simplify the hiring process but also to bring cutting-edge AI solutions into the mix. With tools like Katie AI, our 24/7 medical receptionist, and dedicated Firefly Guides to handle interview setup and candidate screening, we can streamline your staffing and operational efficiency. Ring of Hires offers a smarter, more effective way to ensure your practice is always running smoothly.
          </p>

          <p className="mb-5 text-xl text-gray-500 leading-8">
            —Paul Toomey, MD, FACS
          </p>
          <a
            href="https://calendly.com/paultoomeymd/30min"
            target="_blank"
            className="border border-main px-2 py-3 text-main rounded-lg text-lg block w-44 text-center hover:bg-main hover:text-white cursor-pointer"
          >
            Book a Demo
          </a>
        </div>
        <img src={PaulPic} />
      </div>

      {/* Have Questions or Need Help Getting Started? */}
      <div className="my-16 bg-blue-100 xl:w-5/6 lg:mx-auto p-6 rounded-xl">
        <div className="py-14 mx-auto sm:w-5/6 px-7 sm:px-0">
          <h2 className="text-2xl md:text-4xl font-bold mb-4 text-center">
            Have Questions or Need Help Getting Started?
          </h2>
          <p className="text-gray-500 text-center">
            Want to see our hiring solution in action? Get a free demo today!
          </p>
          <HelpForm />
        </div>
      </div>
    </>
  );
}

export default NewHome;
